// TODO: Refactor to not use `styled`
"use client";

import { ComponentType, PropsWithChildren, ReactNode, useMemo } from "react";
import {
  Box,
  TableCell,
  TableCellProps,
  TableHead,
  TableRow,
  TableRowProps,
  TableSortLabel,
  Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";

import { DataTableBaseRow, DataTableSortingProps, TableColumn } from "./types";

import { InfoIcon, InfoIconVariants } from "common/components/InfoIcon";

interface DataTableHeadProps<T extends DataTableBaseRow> {
  columns: Array<TableColumn<T>>;
  sorting?: DataTableSortingProps;
  TableRowComponent?: ComponentType<TableRowProps>;
  infoIconVariant?: InfoIconVariants;
  additionalTableHeaderRow?: ReactNode;
}

interface SortableHeaderProps {
  id: string;
  sorted?: boolean;
  sortDir: "asc" | "desc";
  onClick: VoidFunction;
  align?: TableCellProps["align"];
  width?: string;
  children?: ReactNode;
}

const DefaultTableCellComponent = styled(TableCell)(({ theme: { palette } }) => ({
  color: palette.text.secondary,
  fontWeight: 400
}));

const noop = () => undefined;
const SortableHeaderCell: React.FC<SortableHeaderProps> = ({
  sorted = false,
  sortDir = "asc",
  onClick = noop,
  children,
  align,
  width,
  id
}) => {
  return (
    <DefaultTableCellComponent
      sortDirection={sortDir}
      align={align}
      sx={width !== undefined ? { width: width } : undefined}
    >
      <TableSortLabel
        active={sorted}
        aria-label={`Sort column ${sortDir === "asc" ? "ascending" : "descending"}`}
        direction={(sorted && sortDir) || "asc"}
        onClick={onClick}
        hideSortIcon={true}
        data-testid={`${id}-col`}
      >
        {children}
      </TableSortLabel>
    </DefaultTableCellComponent>
  );
};

export const DataTableHeadContent = <T extends DataTableBaseRow>({
  column: { display, info },
  infoIconVariant
}: PropsWithChildren<{
  column: TableColumn<T>;
  infoIconVariant?: InfoIconVariants;
}>): JSX.Element => (
  <Box sx={{ display: "inline-flex", alignItems: "center" }} role="columnheader">
    {typeof display === "string" ? (
      <Typography component="span" variant="body2" fontWeight={600}>
        {display}
      </Typography>
    ) : (
      display
    )}
    {info && (
      <InfoIcon sx={{ ml: 1 }} variant={infoIconVariant}>
        <>{info}</>
      </InfoIcon>
    )}
  </Box>
);

export const DataTableHead = <T extends DataTableBaseRow>({
  columns = [],
  sorting,
  infoIconVariant,
  additionalTableHeaderRow
}: PropsWithChildren<DataTableHeadProps<T>>) => {
  const sortableColumns = useMemo(
    () => columns.filter((c) => !!c.sortable).map((c) => c.id),
    [columns]
  );

  const { changeDirection = noop, changeField = noop } = sorting || {};

  return (
    <TableHead role="rowgroup">
      {additionalTableHeaderRow}
      <TableRow>
        {columns
          .filter((h) => !!h.render)
          .map((column) => {
            const { id, align, width } = column;
            const isSortable = sorting && (sortableColumns.includes(id) || !sortableColumns);
            const content = (
              <DataTableHeadContent column={column} infoIconVariant={infoIconVariant} />
            );
            return sorting && isSortable ? (
              <SortableHeaderCell
                data-testid={`table-head-column-${id}`}
                width={width}
                id={id}
                key={id}
                sorted={sorting.field === id}
                sortDir={sorting.direction}
                align={align}
                onClick={() =>
                  sorting.field === id
                    ? changeDirection(sorting.direction === "asc" ? "desc" : "asc")
                    : changeField(id)
                }
              >
                {content}
              </SortableHeaderCell>
            ) : (
              <TableCell
                data-testid={`table-head-column-${id}`}
                key={id}
                align={align}
                sx={width !== undefined ? { width: width } : undefined}
              >
                {content}
              </TableCell>
            );
          })}
      </TableRow>
    </TableHead>
  );
};
